(function ($) {

  $(document).ready(function () {

    //add ids to forms that need validating
    $("form.woocommerce-checkout").attr("id", "checkMeout");

    //validated form
    const $formIdsToValidate = ["referMems", "wb_form", "checkout-personal-details", "checkout-personal-checkboxes", "checkMeout", "validate-form"];
    const $arrayOfFormsOnPage = $("form");

    var cf7_form = $.map($arrayOfFormsOnPage, function ($i) {
      if ($formIdsToValidate.includes($i.id)) {
        return $($i);
      };
    })

    // add uk phone nomber
    jQuery.validator.addMethod('phoneUK', function (phone_number, element) {
      return this.optional(element) || phone_number.length > 9 &&
        phone_number.match(/^(\(?(0|\+44)[1-9]{1}\d{1,4}?\)?\s?\d{3,4}\s?\d{3,4})$/);
    }, 'Please specify a valid phone number');

    // Matches UK postcode. Does not match to UK Channel Islands that have their own postcodes (non standard UK)
    $.validator.addMethod("postcodeUK", function (value, element) {
      return this.optional(element) || /^((([A-PR-UWYZ][0-9])|([A-PR-UWYZ][0-9][0-9])|([A-PR-UWYZ][A-HK-Y][0-9])|([A-PR-UWYZ][A-HK-Y][0-9][0-9])|([A-PR-UWYZ][0-9][A-HJKSTUW])|([A-PR-UWYZ][A-HK-Y][0-9][ABEHMNPRVWXY]))\s?([0-9][ABD-HJLNP-UW-Z]{2})|(GIR)\s?(0AA))$/i.test(value);
    }, "Please specify a valid UK postcode");


    $.map(cf7_form, function (j) {
      j.validate({
        onfocusout: function (element) {
          jQuery(element).valid();
        },

        success: "jv-valid",
        validClass: "jv-valid",
        errorClass: "jv-error",
        ignore: "#account_password",


        invalidHandler: function (form, validator) {

          if (validator.numberOfInvalids() === 1) {
            $("#jv-errornotice strong.single").show();
          } else {
            $("#jv-errornotice strong.plural").show();
          }
          validator.defaultShowErrors();
        },

        // add the name of each field you want to validate
        rules: {
          fname: "required",
          lname: "required",
          company: "required",
          describe: "required",
          yourrole: "required",
          personal_first_name: "required",
          personal_last_name: "required",
          personal_company: "required",
          personal_code: "required", //code of conduct
          personal_email: {
            required: true,
            email: true
          },
          // WB form
          wp_email: {
            required: true,
            email: true
          },
          company_name: "required",
          county_name: "required",
          addr_ln1: "required",
          phonenumber: {
            required: true,
            phoneUK: true
          },
          tel_407: {
            required: true,
            phoneUK: true
          },
          personal_phone: {
            required: false,
            phoneUK: true
          },
          billing_first_name: "required",
          billing_last_name: "required",
          billing_company: "required",
          billing_address_1: "required",
          billing_city: "required",
          // billing_state: "required",
          billing_postcode: {
            required: true,
            postcodeUK: true
          },
        //   billing_phone: {
        //     required: true,
        //     phoneUK: true
        //   },
          town_name: "required",
          c_postcode: {
            required: true,
            postcodeUK: true
          },
          email: {
            required: true,
            email: true
          },
          fremail: {
            required: true,
            email: true
          },
          'entrepreneur[]': {
            required: true,
            maxlength: 2
          },
          'personal_turnover': {
            required: true,
            maxlength: 2
          },
          'personal_professional': {
            required: true,
            maxlength: 2
          },
          'personal_entrepreneur': {
            required: true,
            maxlength: 2
          }
        },
        // error placement for checkbox
        errorPlacement: function (error, element) {
          // console.log(element);
          // console.log(element.attr("type"));
          if (element.attr("type") == "checkbox") {
            error.insertAfter($(element).next('.wpcf7-list-item-label'));
          } else {
            error.insertAfter($(element));
          }
        },
        messages: {
          'entrepreneur[]': {
            required: "Please tick this box to proceed",
            maxlength: "Check no more than {0} boxes"
          },
          'personal_entrepreneur': {
            required: "You must be an Entrepreneur, Founder, Business owner or Managing Director.",
            maxlength: "Check no more than {0} boxes"
          },
          'personal_turnover': {
            required: "You must have a turn over of over £1m p/a",
            maxlength: "Check no more than {0} boxes"
          },
          'personal_professional': {
            required: "You must be one of the listed professions",
            maxlength: "Check no more than {0} boxes"
          },
          'personal_code': {
            required: "You must agree to our Code of conduct to apply",
            maxlength: "Check no more than {0} boxes"
          },

          fname: "Please enter your firstname",
          lname: "Please enter your lastname",
          company: "Please enter your company",
          describe: "Please describe your company",
          email: "Please enter your email address",
          yourrole: "Please enter your role",

          personal_first_name: "Please enter your firstname",
          personal_last_name: "Please enter your lastname",
          personal_company: "Please enter your company",
          personal_email: "Please enter your email",
          personal_phone: "Please enter your phone number",

          billing_first_name: "Please enter your firstname",
          billing_last_name: "Please enter your lastname",
          billing_company: "Please enter your company",
          billing_address_1: "Please enter your address",
          billing_city: "Please enter your city",
          billing_state: "Please enter your county",
          // billing_postcode: "Please enter your postcode",
          billing_phone: "Please enter your phone number"
        },
      });
    });



    // contact form 7 forms


    	var cf7_form = $('form.wpcf7-form');

    	for (var i = 0; i < cf7_form.length; i++) {

    	  cf7_form.eq(i).validate({
    	    onfocusout: function (element) {
    	      jQuery(element).valid();
    	    },

    	    success: "jv-valid",
    	    validClass: "jv-valid",
    	    errorClass: "jv-error",

    	    invalidHandler: function (form, validator) {

    	      if (validator.numberOfInvalids() === 1) {
    	        $("#jv-errornotice strong.single").show();
    	      } else {
    	        $("#jv-errornotice strong.plural").show();
    	      }
    	      validator.defaultShowErrors();
    	    },

    	    // add the name of each field you want to validate
    	    rules: {
    	      fname: "required",
    	      lname: "required",
    	      company: "required",
    	      describe: "required",
    	      yourrole: "required",
    	      email: {
    	        required: true,
    	        email: true
    	      },
    	      'entrepreneur[]': {
    	        required: true,
    	        maxlength: 2
    	      }
    	    },
    	    // error placement for checkbox
    	    errorPlacement: function (error, element) {
    	      console.log(element.attr("type"));
    	      if (element.attr("type") == "checkbox") {
    	        error.insertAfter($(element).next('.wpcf7-list-item-label'));
    	      } else {
    	        error.insertAfter($(element));
    	      }
    	    },
    	    messages: {
    	      'entrepreneur[]': {
    	        required: "Please tick this box to proceed",
    	        maxlength: "Check no more than {0} boxes"
    	      },
    	      fname: "Please enter your firstname",
    	      lname: "Please enter your lastname",
    	      company: "Please enter your company",
    	      describe: "Please describe your company",
    	      email: "Please enter your email address",
    	      yourrole: "Please enter your role"
    	    },
    	  });

      }
      


    // add Asterisk to required checkbox's
    $('input[name = "entrepreneur[]"] + span').append("<sup>*</sup>");

  });
})(jQuery);